import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Coding Interview Tips",
  "author": "tzookb",
  "type": "post",
  "date": "2022-06-28T13:50:08.000Z",
  "url": "/coding-interview-tips/",
  "featuredImage": "./main.jpg",
  "desc": "tips and snippets to help you nail the coding interview",
  "categories": ["coding", "interviewing"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Generate a list of  0,1 as binary representation.
It's great if you have any challeges that can use bit manipulations.
For example return all permutations of a list.`}</p>
    <pre><code parentName="pre" {...{}}>{`def createBin(size):
  num = 2**size - 1
  binned = bin(num)
  res = [int(d) for d in str(binned)[2:]]
  return res
`}</code></pre>
    <p>{`create linked list from an array, transform linked list to an array:`}</p>
    <pre><code parentName="pre" {...{}}>{`def buildList(arr):
    fake_head = ListNode()
    prev = fake_head
    for item in arr:
        next_val = ListNode(item)
        prev.next = next_val
        prev = next_val
    return fake_head.next

def getArrFromList(head):
    arr = []
    cur = head
    while cur:
        arr.append(cur.val)
        cur = cur.next
    return arr
`}</code></pre>
    <p>{`heap with custom comparator:`}</p>
    <pre><code parentName="pre" {...{}}>{`class Node(object):
    def __init__(self, count, val):
        self.count = count
        self.val = val

    def __lt__(self, other):
        return self.count < other.count

    # just a nice print
    def __repr__(self):
        return f'{self.val}: {self.count}'

nodes = [Node(1,1), Node(2,2)]
heapify(nodes)
`}</code></pre>
    <p>{`Create a matrix with width*height:`}</p>
    <pre><code parentName="pre" {...{}}>{`matrix = [[0 for x in range(w)] for y in range(h)] 
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      